html,
body {
	height: 100vh;
	background-image: radial-gradient(
			circle at 16% 83%,
			rgba(148, 148, 148, 0.06) 0%,
			rgba(148, 148, 148, 0.06) 50%,
			rgba(63, 63, 63, 0.06) 50%,
			rgba(63, 63, 63, 0.06) 100%
		),
		radial-gradient(
			circle at 68% 87%,
			rgba(66, 66, 66, 0.06) 0%,
			rgba(66, 66, 66, 0.06) 50%,
			rgba(105, 105, 105, 0.06) 50%,
			rgba(105, 105, 105, 0.06) 100%
		),
		radial-gradient(
			circle at 38% 50%,
			rgba(123, 123, 123, 0.06) 0%,
			rgba(123, 123, 123, 0.06) 50%,
			rgba(172, 172, 172, 0.06) 50%,
			rgba(172, 172, 172, 0.06) 100%
		),
		linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0));
}

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

h1,
p,
a {
	color: white;
	font-family: "Poppins", sans-serif;
}

.home,
.cope {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1em;
	height: 100vh;
	max-width: 30rem;
	text-align: center;
	margin: 0 auto;
}

.home__btn,
.cope__btn {
	background-color: #f5f5f5;
	border: none;
	border-radius: 5px;
	padding: 0.5em 1em;
	font-size: 1.2em;
	font-weight: 600;
	color: #000;
	cursor: pointer;
	user-select: none;
}

.cope__message {
	font-size: 1.2em;
	/* font-weight: 600; */
}
